<template>

  <div class="container user-section">
		<div class="row">
			<div class="col-12">

        <b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

        <div class="form-group text-center pb-2" v-if="mode == 'list'">
          <a class="btn btn-success" v-on:click="newTicket()"><i class="fas fa-plus-circle"></i> Uusi huoltoilmoitus</a>
        </div>

        <b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>

        <b-overlay variant="white" :show="uploading" no-wrap>
          <template #overlay>
            <div class="text-center text-dark p-4">
              <div class="mb-3">Siirretään tiedostoja...</div>
                <b-progress
                  min="1"
                  max="100"
                  :value="progress"
                  variant="success"
                  height="10px"
                  class="mx-n4 rounded-0"
                ></b-progress>
              </div>
            </template>
        </b-overlay>

        <form @submit.prevent="saveTicket" v-if="mode == 'new'">

          <div class="form-group row pb-4">
            <div class="col-6">
              <a class="btn btn-secondary" v-on:click="hide()"><i class="fas fa-arrow-circle-left"></i> Takaisin</a>
            </div>
            <div class="col-6 text-right">
              <button class="btn btn-success" type="submit"><i class="fas fa-save"></i> Tallenna</button>
            </div>
          </div>

          <div class="form-group mobile">
            <label class="form-label">Otsikko</label>
            <input type="text" class="form-control" v-model="new_data.title" required />
          </div>

          <div class="form-group mobile" v-if="sites.length > 0">
            <label class="form-label">Kohde</label>
            <select class="form-control" v-model="new_data.site_id" required>
              <option v-for="(site) in sites" :key="site.id" :value="site.id">{{ site.name }}</option>
            </select>
          </div>

          <div class="form-group mobile" v-if="$parent.$parent.usergroup == 'Manager-employee'" >
            <label class="form-label">Osoita työntekijälle</label>
            <select v-model="new_data.assigned_to" class="form-control" >
              <option value="0">- valitse työntekija -</option>
              <option v-for="(employee) in employees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
            </select>
          </div>

          <div class="form-group mobile" v-if="$parent.$parent.usergroup == 'Manager-employee'" >
            <label class="form-label">Työn tyyppi</label>
            <select v-model="new_data.type" class="form-control" required>
              <option v-for="(type) in types" :key="type.id" :value="type.id">{{ type.name }}</option>
            </select>
          </div>

          <div class="form-group mobile">
            <label class="form-label">Kuvaile huollon tarvetta</label>
            <textarea class="form-control" v-model="new_data.description" required></textarea>
          </div>

          <div class="form-group mobile" v-if="$parent.$parent.usergroup != 'Resident'" >
            <label class="form-label">Kohteen osoite / asunto</label>
            <input type="text" class="form-control" v-model="new_data.site_address" required />
          </div>

          <div class="form-group mobile">
            <label class="form-label">Kohteen tarkenne - missä?</label>
            <input type="text" class="form-control" v-model="new_data.site_description" required />
          </div>

          <div class="form-group mobile select">
            <label class="form-label">Kuinka saa tulla kohteeseen?</label>
            <div class="p-3">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="entry-1" value="1" v-model="new_data.site_entry">
                <label class="custom-control-label" for="entry-1">Soita ja sovi</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="entry-2" value="2" v-model="new_data.site_entry">
                <label class="custom-control-label" for="entry-2">Yleisavaimella</label>
              </div>
            </div>
          </div>

          <div class="form-group mobile" v-if="$parent.$parent.usergroup == 'Manager-employee'" >
            <label class="form-label">Sovitun käynnin ajankohta</label>
            <input type="datetime-local" v-model="new_data.site_entry_date" class="form-control" />
          </div>

          <div class="form-group mobile select">
            <label class="form-label">Onko kohteessa lemmikkejä?</label>
            <div class="p-3">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="pets" value="1" v-model="new_data.site_pets">
                <label class="custom-control-label" for="pets">Kyllä</label>
              </div>
            </div>
            <div class="col-12" v-if="new_data.site_pets == 1">
              <div class="form-group mobile">
                <label class="form-label">Lisätiedot</label>
                <input type="text" class="form-control" v-model="new_data.site_other" />
              </div>
            </div>
          </div>

          <div class="col-12">
            <p><a href="#" @click.stop.prevent="$refs.file.click()" class="btn btn-secondary"><i class="fas fa-image"></i> Lataa kuva</a></p>
            <p v-if="typeof new_data.new_files != 'undefined' && new_data.new_files.length > 0">{{ new_data.new_files.length }} liitettä.</p>
          </div>

          <div class="col-12 mobile-bottom-btns">
            <button class="btn btn-success" type="submit"><i class="fas fa-save"></i> Tallenna</button>
          </div>

        </form>

        <div class="d-none"><input type="file" @change="selectFile" ref="file" multiple="multiple"></div>

        <div class="row ticket-details" v-if="mode == 'view'">
            <div class="col-12" v-if="tab == 'info'">

              <div class="form-group pb-2 text-right" :class="[$parent.$parent.usergroup == 'Manager-employee' ? 'small-tabs' : '']">
                <a class="btn btn-secondary float-left" v-on:click="hide()"><i class="fas fa-arrow-circle-left"></i> Takaisin</a>
                <a class="tabs active" v-on:click="tab = 'info'"><i class="fas fa-info-circle"></i> Info</a>
                <a class="tabs" v-on:click="tab = 'service'" v-if="$parent.$parent.usergroup == 'Manager-employee'"><i class="fas fa-tools"></i> Huolto</a>
                <a class="tabs" v-on:click="tab = 'images'"><i class="fas fa-images"></i> Kuvat</a>
              </div>

              <div class="mb-3" :class="['status-' + ticket.status]">
                <div class="ticket-status text-center">
                  <span></span>
                  <div class="progress"><div class="progress-bar"></div></div>
                </div>
              </div>

              <span class="assigned_to" v-if="$parent.$parent.usergroup == 'Manager-employee'">
                <span class="title">OSOITETTU</span>
                <span class="name">
                  <select v-model="ticket.assigned_to" class="form-control" v-on:change="changeEmployee()" >
                    <option value="0">- valitse työntekija -</option>
                    <option v-for="(employee) in employees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
                  </select>
                </span>
              </span>

              <div class="block ticket-summary" v-if="ticket.summary != '' && $parent.$parent.usergroup != 'Manager-employee'">
                <h3>{{ ticket.updated | formatDateTimePretty }}</h3>
                <p>{{ ticket.summary }}</p>
                <i class="fas fa-tools"></i>
              </div>

              <span class="sitename mt-3 mb-2" v-if="$parent.$parent.usergroup == 'Manager-employee'">{{ ticket.site_name }} - {{ ticket.site_address }}</span>

              <div class="icon-info" v-if="ticket.status != 4">
                <i class="fas fa-calendar-star"></i>
                <span class="date" :class="ticket.created_by == $parent.$parent.userid ? 'mt-2' : ''">{{ ticket.created | formatDateTimePretty }}</span>
                <span class="created_by" v-if="ticket.created_by != $parent.$parent.userid">{{ ticket.site_resident }}</span>
              </div>

              <div class="icon-info" v-if="ticket.site_entry_date != null">
                <i class="fas fa-walking"></i>
                <span class="date mt-2">{{ ticket.site_entry_date | formatDateTimePretty }}</span>
              </div>

              <div class="last_check" v-if="ticket.status == 4">
                <div class="check-info">
                  <span class="title">Seuraava kuittaus mennessä</span>
                  <span class="date strong">{{ ticket.next_check | formatDate }}</span>
                </div>
                <div class="check-info" v-if="ticket.last_check != null">
                  <span class="title">Edellinen kuittaus</span>
                  <span class="date">{{ ticket.last_check | formatDateTimePretty }}</span>
                  <span class="created_by">{{ ticket.last_check_by_name }}</span>
                </div>
              </div>

              <h3 class="mt-3 mb-2">{{ ticket.title }}</h3>
              <p class="mb-4">{{ ticket.description }}</p>

              <div class="ticket-detail">
                <h5>Kohteen tarkenne</h5>
                <p>{{ ticket.site_description }}</p>
              </div>

              <div class="ticket-detail">
                <h5>Kuinka saamme tulla kohteeseen?</h5>
                <p v-if="ticket.site_entry == 1">Soita ja sovi</p>
                <p v-if="ticket.site_entry == 2">Yleisavaimella</p>
              </div>

              <div class="ticket-detail">
                <h5>Onko kohteessa lemmikkejä?</h5>
                <p v-if="ticket.site_pets == 0">Ei.</p>
                <p v-if="ticket.site_pets == 1">Kyllä, {{ ticket.site_other }}</p>
              </div>

              <div class="status-toggle mt-5" v-if="$parent.$parent.usergroup == 'Manager-employee' && ticket.status != 4">
                <button class="btn mr-2" v-on:click="setStatus(2)" :class="[ticket.status == 2 ? 'btn-secondary' : 'btn-success']"><i class="fas fa-tools"></i> Käynnissä</button>
                <button class="btn " v-on:click="setStatus(3)" :class="[ticket.status == 3 ? 'btn-secondary' : 'btn-success']"><i class="fas fa-check-circle"></i> Valmis</button>
              </div>

              <div class="status-toggle mt-5" v-if="$parent.$parent.usergroup == 'Manager-employee' && ticket.status == 4">
                <button class="btn btn-success" v-on:click="checkDone(ticket.id)"><i class="fas fa-check-circle"></i> Kuittaa tehdyksi</button>
              </div>

            </div>

            <div class="col-12" v-if="tab == 'service' && $parent.$parent.usergroup == 'Manager-employee'">

              <div class="form-group pb-2 text-right" :class="[$parent.$parent.usergroup == 'Manager-employee' ? 'small-tabs' : '']">
                <a class="btn btn-secondary float-left" v-on:click="hide()"><i class="fas fa-arrow-circle-left"></i> Takaisin</a>
                <a class="tabs" v-on:click="tab = 'info'"><i class="fas fa-info-circle"></i> Info</a>
                <a class="tabs active" v-on:click="tab = 'service'" v-if="$parent.$parent.usergroup == 'Manager-employee'"><i class="fas fa-tools"></i> Huolto</a>
                <a class="tabs" v-on:click="tab = 'images'"><i class="fas fa-images"></i> Kuvat</a>
              </div>

              <div class="mb-3" :class="['status-' + ticket.status]">
                <div class="ticket-status text-center">
                  <span></span>
                  <div class="progress"><div class="progress-bar"></div></div>
                </div>
              </div>

              <form @submit.prevent="saveService">

                <div class="form-group mobile">
                  <label class="form-label">Seloste (julkinen)</label>
                  <textarea class="form-control" v-model="ticket.summary"></textarea>
                </div>

                <div class="log-container mobile" v-if="typeof ticket.log != 'undefined' && ticket.log.length > 0">
                  <p v-for="(log) in ticket.log" :key="log.id" ><span class="from">{{ log.fullname }} {{ log.created | formatDateTime }}</span>{{ log.summary }}</p>
                </div>

                <div class="form-group mobile">
                  <label class="form-label">Työseloste (sisäinen)</label>
                  <textarea class="form-control" v-model="ticket.summary_internal"></textarea>
                </div>

                <div class="form-group mobile">
                  <label class="form-label">Kilometrit</label>
                  <input type="text" class="form-control" v-model="ticket.distance" />
                </div>

                <div class="form-group mobile">
                  <label class="form-label">Reitti</label>
                  <input type="text" class="form-control" v-model="ticket.route" />
                </div>

                <div class="form-group mobile">
                  <label class="form-label">Sovitun käynnin ajankohta</label>
                  <input type="datetime-local" v-model="ticket.site_entry_date" class="form-control" />
                </div>

                <div class="form-group row pb-4" v-if="0">
                  <div class="col-6">
                    <a class="btn btn-secondary" v-on:click="hide()"><i class="fas fa-arrow-circle-left"></i> Takaisin</a>
                  </div>
                  <div class="col-6 text-right">
                    <button class="btn btn-success" type="submit"><i class="fas fa-save"></i> Tallenna</button>
                  </div>
                </div>

                <div class="col-12 mobile-bottom-btns">
                  <button class="btn btn-success" type="submit"><i class="fas fa-save"></i> Tallenna</button>
                </div>
              </form>

            </div>

            <div class="col-12" v-if="tab == 'images'">

              <div class="form-group pb-2 text-right" :class="[$parent.$parent.usergroup == 'Manager-employee' ? 'small-tabs' : '']">
                <a class="btn btn-secondary float-left" v-on:click="hide()"><i class="fas fa-arrow-circle-left"></i> Takaisin</a>
                <a class="tabs" v-on:click="tab = 'info'"><i class="fas fa-info-circle"></i> Info</a>
                <a class="tabs" v-on:click="tab = 'service'" v-if="$parent.$parent.usergroup == 'Manager-employee'"><i class="fas fa-tools"></i> Huolto</a>
                <a class="tabs active" v-on:click="tab = 'images'"><i class="fas fa-images"></i> Kuvat</a>
              </div>

              <div class="mb-3" :class="['status-' + ticket.status]">
                <div class="ticket-status text-center">
                  <span></span>
                  <div class="progress"><div class="progress-bar"></div></div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-12 pt-2">
                  <p class="text-center mb-4" v-if="ticket.status == 1 || ticket.status == 2"><a href="#" @click.stop.prevent="$refs.file.click()" class="btn btn-success"><i class="fas fa-image"></i> Lataa kuva</a></p>
                  <div v-if="typeof ticket.files != 'undefined' && ticket.files.length == 0" class="alert alert-info my-3">Ei kuvia.</div>
                  <div class="row file-list" v-if="typeof ticket.files != 'undefined' && ticket.files.length > 0">
                    <div class="col-6 col-md-4 file" v-for="(file) in ticket.files" :key="file.id" v-on:click="showImage(['/api/files/' + file.id])">
                      <img :src="['/api/files/' + file.id]" :alt="file.filename" />
                      <p class="blue">{{ file.fullname }}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        </div>

        <div class="filters mobile" v-if="mode == 'list' && $parent.$parent.usergroup != 'Resident'">
          <span class="title">SUODATA</span>
          <div class="filter" v-if="sites.length > 0">
            <select v-model="filters.site">
              <option value="">Kohde</option>
              <option v-for="(site) in sites" :key="site.id" :value="site.id">{{ site.name }}</option>
            </select>
          </div>
          <div class="filter">
            <select v-model="filters.status">
              <option value="">Tila</option>
              <option v-for="(status) in statuses" :key="status.id" :value="status.id">{{ status.name }}</option>
            </select>
          </div>
          <div class="filter">
            <select v-model="filters.type">
              <option value="">Tyyppi</option>
              <option v-for="(filter) in types" :key="filter.id" :value="filter.id">{{ filter.name }}</option>
            </select>
          </div>
          <div class="filter" v-if="$parent.$parent.usergroup == 'Manager-employee'">
            <select v-model="filters.assigned_to">
              <option value="">Osoitettu</option>
              <option value="all">Kaikki</option>
              <option value="me">Omat</option>
              <option value="noone">Ei osoitusta</option>
            </select>
          </div>
          <div class="filter" v-if="$parent.$parent.usergroup == 'Manager-employee'">
            <select v-model="filters.site_entry_date" style="width:120px;">
              <option value="">Käynnin ajankohta</option>
              <option value="today">Tänään</option>
              <option value="week">Seuraavat 7 päivää</option>
            </select>
          </div>
        </div>

        <div class="row px-2" v-if="visible > 0 && mode == 'list'">
          <div class="col-12 col-md-6 col-lg-4" v-for="item in items" :key="item.id">
            <div class="block ticket" :id="['ticket-' + item.id]" :class="['status-' + item.status]">
              <div class="content">
                <div class="ticket-status">
                  <span></span>
                  <div class="progress"><div class="progress-bar"></div></div>
                </div>

                <span class="assigned_to" v-if="item.assigned_to > 0 && $parent.$parent.usergroup == 'Manager-employee'">
                  <span class="title">OSOITETTU</span>
                  <span class="name" v-if="item.assigned_to != $parent.$parent.userid">{{ item.assigned_to_name }}</span>
                  <span class="name strong" v-if="item.assigned_to == $parent.$parent.userid">SINÄ</span>
                </span>

                <span class="new" v-if="item.status == 1 && $parent.$parent.usergroup == 'Manager-employee'"><span class="fa-stack"><i class="fas fa-star fa-stack-2x"></i><i class="fas fa-exclamation fa-stack-1x fa-inverse"></i></span></span>
                <span class="date-small" v-if="item.status != 4 && $parent.$parent.usergroup == 'Manager-employee'">{{ item.created | formatDateTimePretty }}</span>
                <span class="date-small" v-if="item.status == 4 && $parent.$parent.usergroup == 'Manager-employee'" :class="[ item.next_check_days < 7 ? 'check-now' : '']"><strong>{{ item.next_check_days }} pvä jäljellä</strong></span>
                <span class="sitename" v-if="$parent.$parent.usergroup == 'Manager-employee'">{{ item.site_name }} - {{ item.site_address }}</span>
                <h3 class="mt-0" v-if="$parent.$parent.usergroup == 'Manager-employee'">{{ item.title }}</h3>
                <h3 class="my-3" v-if="$parent.$parent.usergroup != 'Manager-employee'">{{ item.title }}</h3>

                <div class="icon-info" v-if="item.status == 3">
                  <i class="fas fa-calendar-check"></i>
                  <span class="title">Valmistunut:</span>
                  <span class="date">{{ item.closed | formatDateTimePretty }}</span>
                </div>

                <div class="icon-info" v-if="item.status == 2">
                  <i class="fas fa-calendar-edit"></i>
                  <span class="title">Viimeisin merkintä:</span>
                  <span class="date">{{ item.updated | formatDateTimePretty }}</span>
                  <span class="updated_by" v-if="$parent.$parent.usergroup == 'Manager-employee'">{{ item.updated_by_name }}</span>
                </div>

                <div class="icon-info" v-if="$parent.$parent.usergroup != 'Manager-employee'">
                  <i class="fas fa-calendar-star"></i>
                  <span class="title">Ilmoitus luotu:</span>
                  <span class="date">{{ item.created | formatDateTimePretty }}</span>
                  <span class="created_by" v-if="item.created_by != $parent.$parent.userid">{{ item.site_resident }}</span>
                </div>

                <div class="icon-info" v-if="item.status == 4 && item.last_check != null">
                  <i class="fas fa-tools"></i>
                  <span class="title">Viimeisin kuittaus:</span>
                  <span class="date">{{ item.last_check | formatDateTimePretty }}</span>
                  <span class="created_by">{{ item.last_check_by_name }}</span>
                </div>

                <div class="icon-info" v-if="item.site_entry_date != null">
                  <i class="fas fa-walking"></i>
                  <span class="title">Sovittu käynti:</span>
                  <span class="date">{{ item.site_entry_date | formatDateTimePretty }}</span>
                </div>

                <span class="open" v-on:click="show(item.id)" v-if="$parent.$parent.usergroup != 'Manager-employee'">Näytä</span>
                <span class="open" v-on:click="show(item.id)" v-if="$parent.$parent.usergroup == 'Manager-employee'">Avaa</span>

              </div>
            </div>
          </div>
        </div>

        <b-modal id="image-modal" size="xl" centered v-model="imageModal"><img :src="imageModalImg" /></b-modal>

				<b-alert class="my-3" variant="info" :show="!loading && items.length == 0 && mode == 'list'">Ei huoltoilmoituksia.</b-alert>

				<ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 1 && mode == 'list'">
					<li v-for="n in pages" :key="n" class="page-item" :class="[page == (n-1) ? 'active' : '']"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
				</ul>

			</div>
		</div>
	</div>

</template>

<script>
export default {
  data: function () {
		return {
			loading: true,
      sending: false,
			error: false,
      statuses: [ { id: 1, name: 'Uusi' }, { id: 2, name: 'Kesken' }, { id: 3, name: 'Valmis' }, { id: 4, name: 'Toistuva' } ],
      types: [],
      sites: [],
      employees: [],
      filters: {
        site: '',
        status: '',
        type: '',
        assigned_to: '',
        site_entry_date: ''
      },
      ticket: false,
      tab: 'info',
      mode: 'list',
			items: [],
      new_data: [],
      files: [],
      uploading: false,
      progress: 0,
			visible: 0,
			perpage: 20,
			pages: 0,
			page: 0,
      imageModal: false,
      imageModalImg : ''
		}
	},
	created: function () {
		var vm = this;
    this.axios.get('/api/filters?limit=500')
		.then(function (response) {
			if(response.status == 200) {
        (response.data.results).forEach(filter => {
          //if(filter.type == 1) vm.areas.push(filter);
          if(filter.type == 2) vm.types.push(filter);
        })
			}
		})
		.catch(function (error) {
			console.log(error);
		})

    if(vm.$parent.$parent.usergroup == 'Manager-employee') {
      this.axios.get('/api/sites?limit=500')
      .then(function (response) {
        if(response.status == 200) {
          vm.sites = response.data.results;
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      this.axios.get('/api/users?type=employees&limit=500')
      .then(function (response) {
        if(response.status == 200) {
          vm.employees = response.data.results;
        }
      })
      .catch(function (error) {
        console.log(error);
      })
    }

    vm.load();

    if(typeof vm.$route.query.id != 'undefined') {
      if(vm.$route.query.id == 'new') vm.newTicket();
    }
	},
	methods: {
		load: function () {
			var vm = this;
			vm.loading = true;
			this.axios.get('/api/tickets?site=' + vm.$parent.$parent.current_site + '&limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage)  + '&filters=' + JSON.stringify(vm.filters))
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.pages = Math.ceil(response.data.total / vm.perpage);
          vm.items = response.data.results;
					vm.visible = response.data.results.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.pages = 0;
				vm.loading = false;
			})
		},
		showpage: function (id) {
			this.page = id;
		},
    newTicket: function () {
      this.new_data = { site_id: this.$parent.$parent.current_site, site_entry: 1, site_entry_date: null, site_pets: 0, type: 45, new_files: [] };
      this.mode = 'new';
    },
    hide: function () {
      this.ticket = false;
      this.tab = 'info';
      this.mode = 'list';

      if(typeof this.$route.query.id != 'undefined') {
        if(this.$route.query.id == 'new') this.$router.push('/omat-huoltoilmoitukset');
      }
    },
    show: function (id, showtab = 'info') {
      var vm = this;
      vm.loading = true;

      this.axios.get('/api/tickets/' + id)
      .then(function (response) {
        if(response.data.success) {
          vm.ticket = response.data.object;
          if(response.data.object.site_entry_date != null) vm.ticket.site_entry_date = vm.$options.filters.formatInputDateTime(response.data.object.site_entry_date);
          vm.mode = 'view';
          vm.loading = false;
          vm.tab = showtab;
        } else {
          vm.$parent.makeToast('danger', 'Huoltotyön lataus', 'Epäonnistui!');
          vm.loading = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        vm.$parent.makeToast('danger', 'Huoltotyön lataus', 'Epäonnistui!');
        vm.loading = false;
      })
    },
    showImage: function(image) {
      this.imageModalImg = image;
      this.imageModal = true;
    },
    selectFile: function() {
      for( var i = 0; i < this.$refs.file.files.length; i++ ){
				let file = this.$refs.file.files[i];
        this.files.push(file);
			}
      this.uploadFiles();
    },
    uploadFiles: function() {
      var vm = this;

      if(vm.files.length == 0) return;

      vm.uploading = true;
      vm.progress = 0;

      let formData = new FormData();
      if(typeof vm.ticket.id != 'undefined') formData.append('ticket_id', vm.ticket.id);
      vm.files.forEach((f,x) => {
        formData.append('file'+(x+1), f);
      });

      this.axios.post('/api/files', formData, {
				headers: {
          'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: progressEvent => {
					vm.progress = Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 );
				}
			})
			.then(function (response) {
        if(response.data.success) {
          vm.$parent.makeToast('success', 'Tiedostojen siirto', 'Onnistui!');
          vm.uploading = false;
          vm.files = [];
          if(typeof vm.ticket.id != 'undefined') { vm.show(vm.ticket.id); }
          else {
            response.data.object.forEach(element => vm.new_data.new_files.push(element));
            //vm.edit_data.files.push(response.data.object);
          }
        } else {
          vm.$parent.makeToast('danger', 'Tiedostojen siirto', 'Epäonnistui!');
          vm.uploading = false;
          vm.files = [];
        }
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Tiedostojen siirto', 'Epäonnistui!');
				vm.uploading = false;
        vm.files = [];
			});

    },
    saveTicket: function() {
      var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				this.axios({
					method: 'post',
					url: '/api/tickets',
					data: vm.new_data
				})
				.then(function (response) {
					if(response.data.success) {
            if(typeof response.data.object.id != 'undefined') vm.show(response.data.object.id);
						vm.$parent.makeToast('success', 'Huoltoilmoituksen tallennus', 'Onnistui!');
						vm.load();
					} else {
						vm.$parent.makeToast('warning', 'Huoltoilmoituksen tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Huoltoilmoituksen tallennus', 'Epäonnistui!');
				})
			}
    },
    setStatus: function(s_id) {
      var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				this.axios({
					method: 'put',
					url: '/api/tickets',
					data: { id: vm.ticket.id, status: s_id }
				})
				.then(function (response) {
					if(response.data.success) {
            if(typeof response.data.object.id != 'undefined') vm.show(response.data.object.id);
						vm.$parent.makeToast('success', 'Huoltoilmoituksen tallennus', 'Onnistui!');
						vm.load();
					} else {
						vm.$parent.makeToast('warning', 'Huoltoilmoituksen tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Huoltoilmoituksen tallennus', 'Epäonnistui!');
				})
			}
    },
    saveService: function() {
      var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				this.axios({
					method: 'put',
					url: '/api/tickets',
					data: { id: vm.ticket.id, summary: vm.ticket.summary, summary_internal: vm.ticket.summary_internal, distance: vm.ticket.distance, route: vm.ticket.route, site_entry_date: vm.ticket.site_entry_date }
				})
				.then(function (response) {
					if(response.data.success) {
            if(typeof response.data.object.id != 'undefined') vm.show(response.data.object.id, 'service');
						vm.$parent.makeToast('success', 'Huoltoilmoituksen tallennus', 'Onnistui!');
						vm.load();
					} else {
						vm.$parent.makeToast('warning', 'Huoltoilmoituksen tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Huoltoilmoituksen tallennus', 'Epäonnistui!');
				})
			}
    },
    checkDone: function(t_id) {
      var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				this.axios({
					method: 'put',
					url: '/api/tickets',
					data: { id: t_id, check_done: 1 }
				})
				.then(function (response) {
					if(response.data.success) {
            if(typeof response.data.object.id != 'undefined') vm.show(response.data.object.id);
						vm.$parent.makeToast('success', 'Huoltoilmoituksen tallennus', 'Onnistui!');
						vm.load();
					} else {
						vm.$parent.makeToast('warning', 'Huoltoilmoituksen tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Huoltoilmoituksen tallennus', 'Epäonnistui!');
				})
			}
    },
    changeEmployee: function() {
      var vm = this;
      if(!vm.sending) {
				vm.sending = true;
				this.axios({
					method: 'put',
					url: '/api/tickets',
					data: { id: vm.ticket.id, assigned_to: vm.ticket.assigned_to }
				})
				.then(function (response) {
					if(response.data.success) {
            if(typeof response.data.object.id != 'undefined') vm.show(response.data.object.id);
						vm.$parent.makeToast('success', 'Huoltoilmoituksen tallennus', 'Onnistui!');
						vm.load();
					} else {
						vm.$parent.makeToast('warning', 'Huoltoilmoituksen tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Huoltoilmoituksen tallennus', 'Epäonnistui!');
				})
			}
    }
	},
	watch: {
		page: function () {
			this.load();
		},
    '$route.fullPath': function() {
      if(typeof this.$route.query.id != 'undefined') {
        if(this.$route.query.id == 'new') this.newTicket();
        else this.hide();
      } else { this.hide(); }
    },
    '$parent.$parent.current_site': function() {
      this.load();
      this.new_data.site_id = this.$parent.$parent.current_site;
    },
    filters: {
			handler: function () {
				if(this.page == 0) {
					this.load();
				} else {
					this.page = 0;
				}
			},
			deep: true
		}
	}
}
</script>
